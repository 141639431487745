/* agreement page */

'use strict';

$(function () {
  if ($('body#agreement-page').size() > 0) {

    var isAccepted = $('.acceptance-box')
      .find('input:checkbox')[0].checked;

    var agreement = {
      accepted: isAccepted,
      notAccepted: function () {
        return !this.accepted;
      }
    };

    require('rivets').bind($('.registration-wrapper'), { agreement: agreement });
  }
});
