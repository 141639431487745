'use strict';


$(function() {
  if ($('body#login-page').size() > 0) {
    $('#helpLink').click(function() {
      $('#login-form').addClass('hidden');
      $('#helpScreen').removeClass('hidden');
    });

    $('#backToLoginButton').click(function() {
      $('#helpScreen').addClass('hidden');
      $('#login-form').removeClass('hidden');
    });

    $('#backToLoginButton2').click(function() {
      $('#message-sent').addClass('hidden');
      $('#login-form').removeClass('hidden');
    });
  }
});

