/* globals Visualforce */

/* profile page */

'use strict';

require('bootstrap');
var rivets = require('rivets');


$(function() {
  var profile;

  if ($('body#profile-page').size() > 0) {

    $('#editProfileButton').click( function() {
      $('#theModal').modal();
      Visualforce.remoting.Manager.invokeAction('SCOM_ProfileController.getUserProfile',
        function(p, event) {
          profile = p;
          if (event.status) {
            rivets.bind($('#theModal'), {profile: profile});
          }
          else {
            alert(event.message);
          }
        });
    });

    $('#saveProfileButton').click(function() {
      Visualforce.remoting.Manager.invokeAction('SCOM_ProfileController.saveUserProfile',
        profile,
        function(result, event) {
          if (event.status) {
            document.location = window.profilePageUrl + '?profileSaved=1';
          }
          else {
            alert(event.message);
          }
        });
    });
  }
});