/* late fee page */

'use strict';

$(function () {
    if ($('body#late-fee-page').size() > 0) {

        var isAccepted = $('.fee-acceptance-box')
            .find('input:checkbox')[0].checked;

        var fee = {
            accepted: isAccepted,
            notAccepted: function () {
                return !this.accepted;
            }
        };

        require('rivets').bind($('.registration-wrapper'), { fee: fee });
    }
});
