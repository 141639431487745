/* globals Visualforce */

/* landing page */

'use strict';

require('bootstrap');

$(function() {
  function init() {
    $('div.alert').not('.action-feedback').on('closed.bs.alert', function(e) {
      var messageRecipientId = $(e.target).data('messagerecipientid');
      Visualforce.remoting.Manager.invokeAction('SCOM_LandingController.dismissMessage',
        messageRecipientId,
        function(result, event) {
          if (!event.status) {
            alert('unable to save');
            console.log(event);
          }
        });
    });

    $('a.alert-action').on('click', function(e) {
      var msgRecipientId = $(e.target)
                              .closest('div.alert')
                              .data('messagerecipientid');
      Visualforce.remoting.Manager.invokeAction('SCOM_LandingController.executeAction',
        msgRecipientId,
        function(result, event) {
          if (event.status) {
            var saveUrl = window.location.protocol + '//' + window.location.hostname +
                window.location.pathname + '?af=1';
            window.location = saveUrl;
          } else {
            console.log(event);
          }
        });
    });
  }

  if ($('body#landing-page').size() > 0) {
    init();
  }
});
