/* globals Visualforce */

/* case detail page */

"use strict";

$(function() {
    if ($('body#case-page').size() > 0) {

        $('#comment-save-btn').on('click', function() {
            var comment = $('#new-comment').val();
            if (comment.trim() === '') {
                $('#comment-modal').modal('hide');
                return;
            }

            Visualforce.remoting.Manager.invokeAction(
                'SCOM_CaseController.createCaseComment',
                comment,
                $('#case').data('case-id'),
                function(result, event) {
                    if (event.status) {
                        window.location.reload();
                    }
                    else {
                        alert(event.message);
                    }
                });
        });
    }

});
