/* calendar page */

"use strict";

(function($) {

    if ($('body#calendar-page').size() > 0) {

        window.moment = require('moment');
        window._      = require("underscore");
        require("bootstrap-calendar");

        var options = {
            events_source: function() {
                var evts = [];
                for (var i = 0; i < window.events.length; i++) {

                    var evt             = jQuery.extend(true, {}, window.events[i]);
                    var start           = parseInt(evt.start);
                    var end             = parseInt(evt.end);
                    var browserTzOffset = new Date(start).getTimezoneOffset() * 60000;
                    evt.start           = (start - evt.azTzOffset + browserTzOffset) + '';
                    evt.end             = (end - evt.azTzOffset + browserTzOffset) + '';

                    evts.push(evt);
                }

                return evts;
            },
            view: window.screen.width > 768 ? 'month' : 'day',
            tmpl_path: window.templatePath,
            tmpl_cache: false,
            weekbox: false,
            display_week_numbers: false,
            modal: '#event-detail',
            modal_type : 'template',
            modal_title : function (e) { return e.title; },
            onAfterEventsLoad: function(events) {
                if(!events) {
                    return;
                }
                var list = $('#eventlist');
                list.html('');

                $.each(events, function(key, val) {
                    $(document.createElement('li'))
                        .html('<a href="' + val.url + '">' + val.title + '</a>')
                        .appendTo(list);
                });
            },
            onAfterViewLoad: function(view) {
                $('.page-header h3').text(this.getTitle());
                $('.btn-group button').removeClass('active');
                $('button[data-calendar-view="' + view + '"]').addClass('active');
            },
            classes: {
                months: {
                    general: 'label'
                }
            }
        };

        var updatedLocale = {
            d0:         'SUN',
            d1:         'MON',
            d2:         'TUE',
            d3:         'WED',
            d4:         'THU',
            d5:         'FRI',
            d6:         'SAT',
            d_0:        'Sunday',
            d_1:        'Monday',
            d_2:        'Tuesday',
            d_3:        'Wednesday',
            d_4:        'Thursday',
            d_5:        'Friday',
            d_6:        'Saturday',
            title_day:  '{0}, {1} {2}, {3}',
            title_week: '{0}  –  {1}'
        };

        // initialize bootstrap calendar
        var $calendar = $('#calendar');
        window.calendar = $calendar.calendar(options);

        // customize calendar
        $.extend(window.calendar.locale, updatedLocale);
        window.calendar.view();
        window.calendar.getWeekFirstDay = function(dt) {
            return this.getDay(dt);
        };
        window.calendar.getWeekLastDay = function(dt) {
            return this.getDay(new Date(dt.getTime() + 518400000));
        };
        window.calendar.getDay = function(dt) {
            var m = window.calendar.locale['m' + dt.getMonth()];
            var d = dt.getDate();
            return m + ' ' + d;
        };
        window.calendar.getTitle = function() {
            var p = this.options.position.start;
            switch(this.options.view) {
                case 'year':
                    return this.locale.title_year.format(p.getFullYear());
                case 'month':
                    return this.locale.title_month.format(this.locale['m' + p.getMonth()], p.getFullYear());
                case 'week':
                    return this.locale.title_week.format(this.getWeekFirstDay(p), this.getWeekLastDay(p));
                case 'day':
                    return this.locale.title_day.format(this.locale['d_' + p.getDay()], this.locale['m' + p.getMonth()], p.getDate(), p.getFullYear());
            }
            return;
        };

        // reload title using our custom names
        $('.page-header h3').text(window.calendar.getTitle());

        // show calendar
        $calendar.removeClass('hidden');

        $('.btn-group button[data-calendar-nav]').each(function() {
            var $this = $(this);
            $this.click(function() {
                window.calendar.navigate($this.data('calendar-nav'));
                redrawEventsInMonthView();

                // Enable/disable navigation buttons
                var now      = new Date().getTime();
                var plusSix  = now + 15724800000;
                var minusSix = now - 15724800000;
                var selected = window.calendar.options.day === 'now' ?
                               new Date().getTime() :
                               new Date(window.calendar.options.day).getTime();

                if (selected < minusSix) {
                    $('#calendar-nav-prev').prop('disabled', true);
                } else if (selected > plusSix) {
                    $('#calendar-nav-next').prop('disabled', true);
                } else {
                    $('.btn-group button[data-calendar-nav]').filter(':disabled').prop('disabled',false);
                }

            });
        });

        $('.btn-group button[data-calendar-view]').each(function() {
            var $this = $(this);
            $this.click(function() {
                window.calendar.view($this.data('calendar-view'));
                redrawEventsInMonthView();
            });
        });


        // Filters
        // clicks OUTSIDE checkbox/radio btns and labels
        $('li.filter-option').on('click', function(e) {

            e.stopPropagation();

            var $origin = $(e.target);
            if ($origin.is('input') || $origin.is('label')) { return; }

            e.preventDefault();

            $(this).find('input').click();
        });

        // clicks INSIDE checkbox/radio btns and labels
        $('.filter-option input').on('change', function() {

            var $this = $(this),
                $calendar = $('#calendar'),
                filter = $this.closest('.filter-option').data('filter'),
                statuses = 'show-registered-only show-declined-only';

            // event types
            if ($this.is('input:checkbox')) {
                if ($this[0].checked) {
                    $calendar.addClass(filter);
                } else {
                    $calendar.removeClass(filter);
                }
            }

            // statuses
            if ($this.is('input:radio')) {
                $calendar.removeClass(statuses).addClass(filter);
            }

            redrawEventsInMonthView();

            $('.filter-icon').show();
        });

        // prevent dropdown dismissal if user clicks outside list group items
        $('.dropdown-menu').on('click', function(e){
            e.stopPropagation();
        });

        // Utility methods
        var redrawEventsInMonthView = function() {
            if (window.calendar.options.view !== 'month') { return; }

            $('.cal-month-box a.show, .cal-month-box a.hidden')
                .removeClass('show hidden');
            $('.cal-month-box div.events-list').each(function() {
                var $this = $(this);
                var $extra = $this.children('a.evt:visible').slice(3);
                var numExtra = $extra.length;
                if (numExtra > 0) {
                    $extra.addClass('hidden');
                    $this.find('a.more-evts-link')
                        .addClass('show')
                        .find('.num-days')
                            .text(numExtra);
                }
            });
        };



        redrawEventsInMonthView();

    }

}(jQuery));