'use strict';

var rivets = require('rivets');

$(function() {

  if ($('body#registration-select-cores-page').size() > 0) {
    var calculateHours = function () {
      var coreHoursSelected = 0;
      $(':checkbox[data-hours]:checked').each(function(idx, el) {
        coreHoursSelected += 1 * $(el).data('hours');
      });
      state.coreHoursSelected = coreHoursSelected;
      state.electiveHoursSelected = 1 * $('input[id$=electiveHoursSelectedHidden]').val();
      state.hoursRemaining = 1 * $('input[id$=maxHoursHidden]').val() - coreHoursSelected - state.electiveHoursSelected;
      state.hoursRemainingGreen = state.hoursRemaining > 0;
      state.hoursRemainingRed = !state.hoursRemainingGreen;
      state.nonNegativeHoursRemaining = state.hoursRemaining >= 0;
      state.zeroHoursRemaining = state.hoursRemaining === 0;
      state.negativeHoursRemaining = state.hoursRemaining < 0;
    };

    var state = {
      coreHoursSelected: 0
    };

    rivets.bind($('body'), { state: state });

    calculateHours();

    $(':checkbox[data-hours]').change(function() {
      calculateHours();
    });

    // show summary section
    $('.registration-wrapper').css('display', '');
  }
});