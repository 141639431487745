window.$ = window.jQuery = require('jquery');
require('./profile.js');
require('./article.js');
require('./login.js');
require('./agreement.js');
require('./myclasses.js');
require('./calendar.js');
require('./landing.js');
require('./registration/registration');
require('./menu.js');
require('./case.js');
require('./late-fee.js');
require('./global.js');