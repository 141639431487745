'use strict';

var rivets = require('rivets');

$(function() {

  if ($('body#registration-select-electives-page').size() > 0) {
    var calculateHours = function () {
      var electiveHoursSelected = 0;
      $(':checkbox[data-hours]:checked').each(function(idx, el) {
        electiveHoursSelected += 1 * $(el).data('hours');
      });
      state.electiveHoursSelected = electiveHoursSelected;
      state.minHours = 1 * $('input[id$=minHoursHidden]').val();
      state.minElectiveHours = 1 * $('input[id$=minElectiveHoursHidden]').val();
      state.minElectiveHoursSelected = electiveHoursSelected >= state.minElectiveHours;
      state.coreHoursSelected = 1 * $('input[id$=coreHoursSelectedHidden]').val();
      state.hoursRemaining = 1 * $('input[id$=maxHoursHidden]').val() - state.coreHoursSelected -
      electiveHoursSelected;
      state.minHoursSelected = (state.coreHoursSelected + electiveHoursSelected) >= state.minHours;
      state.hoursRemainingGreen = state.hoursRemaining >= 0;
      state.hoursRemainingRed = !state.hoursRemainingGreen;
      state.nonNegativeHoursRemaining = state.hoursRemaining >= 0;
      state.zeroHoursRemaining = state.hoursRemaining === 0;
      state.negativeHoursRemaining = state.hoursRemaining < 0;
      state.canGoBack = state.nonNegativeHoursRemaining && state.minElectiveHoursSelected;
      state.canGoForward = state.nonNegativeHoursRemaining && state.minHoursSelected &&
        state.minElectiveHoursSelected;
    };

    var state = {
      elective3HoursSelected: 0
    };

    rivets.bind($('body'), { state: state });

    calculateHours();

    $(':checkbox[data-hours]').change(function() {
      calculateHours();
    });

    // show summary section
    $('.registration-wrapper').css('display', '');
  }
});