/* globals Visualforce */

/* article page */

'use strict';


$(function() {
  if ($('body#article-page').size() > 0) {
    var addBookmark = function(articleId) {
      Visualforce.remoting.Manager.invokeAction('SCOM_ArticleController.bookmark',
        articleId,
        function(retVal, event) {
          if (!event.status) {
            console.log(event.message);
          }
      });
    };

    var removeBookmark = function(articleId) {
      Visualforce.remoting.Manager.invokeAction('SCOM_ArticleController.removeBookmark',
        articleId,
        function(retVal, event) {
          if (!event.status) {
            console.log(event.message);
          }
      });
    };

    var toggleUpVote = function(articleId) {
      Visualforce.remoting.Manager.invokeAction('SCOM_ArticleController.toggleUpVote',
        articleId,
        function(retVal, event) {
          if (!event.status) {
            console.log(event.message);
          }
      });
    };

    var toggleDownVote = function(articleId) {
      Visualforce.remoting.Manager.invokeAction('SCOM_ArticleController.toggleDownVote',
        articleId,
        function(retVal, event) {
          if (!event.status) {
            console.log(event.message);
          }
      });
    };

    var incrementSpan = function($span) {
      var val = $span.html() - 0;
      val = val + 1;
      $span.html(val);
    };

    var decrementSpan = function($span) {
      var val = $span.html() - 0;
      val = val - 1;
      $span.html(val);
    };

    $('#bookmarkButton').click(function() {
      if ($('#bookmarkButton').hasClass('bookmarked')) {
        removeBookmark($('#bookmarkButton').data('id'));
        $('#bookmarkButton').removeClass('bookmarked');
      }
      else {
        addBookmark($('#bookmarkButton').data('id'));
        $('#bookmarkButton').addClass('bookmarked');
      }
    });

    $('[id$=thumbsUpButton]').click(function() {
      var upButton = $('[id$=thumbsUpButton]');
      var downButton = $('[id$=thumbsDownButton]');
      var upVoteSpan = $('#upVoteCountSpan');
      var downVoteSpan = $('#downVoteCountSpan');
      var isUpVoted = upButton.hasClass('acom-thumb-up-active');
      var isDownVoted = downButton.hasClass('acom-thumb-down-active');
      if (isDownVoted) {
        downButton.removeClass('acom-thumb-down-active');
        downButton.addClass('acom-thumb-down');
        decrementSpan(downVoteSpan);
      }
      if (isUpVoted) {
        decrementSpan(upVoteSpan);
        upButton.removeClass('acom-thumb-up-active');
        upButton.addClass('acom-thumb-up');
      }
      else {
        incrementSpan(upVoteSpan);
        upButton.removeClass('acom-thumb-up');
        upButton.addClass('acom-thumb-up-active');
      }
      toggleUpVote(upButton.data('id'));
    });

    $('[id$=thumbsDownButton]').click(function() {
      var upButton = $('[id$=thumbsUpButton]');
      var downButton = $('[id$=thumbsDownButton]');
      var upVoteSpan = $('#upVoteCountSpan');
      var downVoteSpan = $('#downVoteCountSpan');
      var isUpVoted = upButton.hasClass('acom-thumb-up-active');
      var isDownVoted = downButton.hasClass('acom-thumb-down-active');
      if (isUpVoted) {
        upButton.removeClass('acom-thumb-up-active');
        upButton.addClass('acom-thumb-up');
        decrementSpan(upVoteSpan);
      }
      if (isDownVoted) {
        decrementSpan(downVoteSpan);
        downButton.removeClass('acom-thumb-down-active');
        downButton.addClass('acom-thumb-down');
      }
      else {
        incrementSpan(downVoteSpan);
        downButton.removeClass('acom-thumb-down');
        downButton.addClass('acom-thumb-down-active');
      }
      toggleDownVote(downButton.data('id'));
    });
  }
});

